<script setup>
import { onMounted, ref } from "vue";
import { Logger } from '@/plugins/loggerPlugin.js';

const logger = new Logger('SiteHeader');
let showDropDown = ref(false);

onMounted(() => {
  logger.log("mounted");
});

const closeDropDown = () => {
  showDropDown.value = false;
};
</script>

<template>
  <header>
    <div class="nav">
      <img src="images/webp/hey_logo_solid_blue.webp" alt="Triple Hey" class="logo" @click="$router.push('/')" />
      <div class="links hey-title-font">
        <router-link to="/">Our Game</router-link>
        <router-link to="/about">About Us</router-link>
        <!-- <a href="mailto:info@triplehey.com">Contact Us</a> -->
      </div>
      <div class="socials">
        <a href="https://www.facebook.com/studiotriplehey">
          <img src="images/webp/hey_social_small_blue_fb.webp" alt="" />
        </a>
        <a href="https://www.instagram.com/walkmyron/">
          <img src="images/webp/hey_social_small_blue_ig.webp" alt="" />
        </a>
      </div>
    </div>
    <div class="nav mobile">
      <img src="images/webp/hey_logo_solid_blue.webp" alt="Triple Hey" class="logo" />
      <div class="menu-btn" @click="showDropDown = !showDropDown">
        <img src="images/webp/hamburger.webp" alt="" />
      </div>
    </div>
    <transition name="roll">
      <div v-if="showDropDown" class="dropdown hey-title-font">
        <router-link @click="closeDropDown" to="/">Our Game</router-link>
        <router-link @click="closeDropDown" to="/about">About Us</router-link>
        <a href="mailto:info@triplehey.com">Contact Us</a>
        <div class="socials">
          <a href="https://www.facebook.com/studiotriplehey">
            <img src="images/webp/hey_social_small_blue_fb.webp" alt="" />
          </a>
          <a href="https://www.instagram.com/walkmyron/">
            <img src="images/webp/hey_social_small_blue_ig.webp" alt="" />
          </a>
        </div>
      </div>
    </transition>
  </header>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/hey-variables.scss";

header {
  position: relative;
  padding: 0 1rem;
  z-index: 1;

  .logo{
    cursor: pointer;
  }

  a {
    color: $hey-text-dark;
    text-decoration: none;
    transition: color 200ms;
    margin: 0.5rem 0;
    margin-right: 2rem;

    &:hover {
      color: $hey-text-pink;
    }
  }

  // DESKTOP
  .nav {
    background-color: white;
    color: $hey-text-dark;
    max-width: $site-max-width;
    padding: 0.5rem 2rem;
    margin: 1rem auto;
    border-radius: 5rem;
    display: flex;
    align-items: center;

    .logo {
      height: 3rem;
      margin-top: -0.4rem;
      margin-bottom: -0.4rem;
      margin-right: 2rem;
    }

    .links {
      display: flex;
      flex-wrap: wrap;
    }

    .socials {
      margin-left: auto;
      white-space: nowrap;

      a {
        margin: 0 0 0 1rem;
      }

      img {
        height: 2rem;
      }
    }
  }

  // MOBILE
  .nav.mobile {
    display: none;
    .menu-btn {
      margin-left: auto;
      img {
        height: 1rem;
      }
    }
  }

  .dropdown {
    position: absolute;
    left: 1rem;
    right: 1rem;
    overflow: hidden;
    background: #f8f8f8;
    border-radius: 2rem;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.33);

    a {
      display: block;
      margin: 2rem;
    }

    .socials a {
      display: inline-block;
      margin-top: 0;
      margin-right: 0;
    }
  }

  @include small-size {
    .nav {
      display: none;
    }
    .nav.mobile {
      display: flex;
    }
  }
}

// Dropdown animation
.roll-enter-active,
.roll-leave-active {
  transition: max-height 0.2s ease-in-out;
}
.roll-enter-from,
.roll-leave-to {
  max-height: 0;
}
.roll-enter-to,
.roll-leave-from {
  max-height: 300px;
}
</style>
