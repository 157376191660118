<script setup>
import { onMounted, ref } from "vue";
import { Logger } from '@/plugins/loggerPlugin.js';

const logger = new Logger('SiteFooter');
let email = ref("");

onMounted(() => {
  logger.log("mounted");
});

function onClickSubscribe() {
  alert("Not yet implemented. Email is: " + email.value);
}
</script>

<template>
  <footer class="bg-blue">
    <!-- Background -->
    <div class="capsules-bg blue">
      <div class="blue-bg-gradient"></div>
    </div>
    <div style="height: 8rem"></div>

    <div class="container">
      <!-- Register - TEMP unavailable -->
      <div class="register" style="display: none">
        <div class="hey-title">
          Register to get<br />
          the latest news
        </div>
        <div class="input-group">
          <input type="text" v-model="email" />
          <button @click="onClickSubscribe">Subscribe</button>
        </div>
      </div>

      <!-- Sitemap -->
      <div class="sitemap2">
        <div>
          <img src="images/webp/hey_logo_outline.webp" alt="" />
        </div>
        <div>
          <div class="hey-sub-title">Our Games</div>
          <ul>
            <li><a href="/">Walk my Ron</a></li>
            <li>More to come...</li>
          </ul>
        </div>
        <div>
          <div class="hey-sub-title">About Us</div>
          <ul>
            <li><router-link to="/about">Our Story</router-link></li>
            <li><router-link to="/about">The Team</router-link></li>
            <li><router-link to="/about">Career</router-link></li>
          </ul>
        </div>
        <div>
          <div class="hey-sub-title">Contact Us</div>
          <ul>
            <li><a href="mailto:info@triplehey.com">Info</a></li>
            <li><a href="mailto:media@triplehey.com">Media</a></li>
          </ul>
        </div>
        <div>
          <div class="flex-line">
            <div>
            <div><a href="mailto:info@triplehey.com">info@triplehey.com</a></div>
            <div><a href="mailto:media@triplehey.com">media@triplehey.com</a></div>
            <div><router-link to="/privacy">Privacy Policy</router-link></div>
            </div>
            <div>
              <a href="https://www.facebook.com/studiotriplehey">
                <img src="images/webp/hey_social_small_fb.webp" alt="facebook" />
              </a>
              <a href="https://www.instagram.com/walkmyron/">
                <img src="images/webp/hey_social_small_ig.webp" alt="instagram" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<style lang="scss">
@import "@/assets/styles/hey-variables.scss";

footer {
  .register {
    display: flex;
    flex-direction: column;
    align-items: center;

    .hey-title {
      font-size: 2rem;
      line-height: 2rem;
      text-align: center;
      margin-bottom: 1rem;
    }

    .input-group {
      background: white;
      border-radius: 2rem;
      display: flex;
      padding: 2px;
      max-width: 20rem;

      input {
        font-family: "Palanquin Dark", sans-serif;
        border: 0;
        background: transparent;
        outline: 0;
        padding: 0.5rem 1rem;
        width: 100%;
      }
      button {
        font-family: "Shrikhand", cursive;
        color: $hey-text-light;
        background: $hey-bg-pink;
        border: 0;
        border-radius: 2rem;
        padding: 0.5rem 1rem;
        cursor: pointer;

        &:hover {
          background: $hey-text-pink;
        }
      }
    }
  }

  .sitemap2 {
    display: grid;
    grid-template-columns: auto 1fr 1fr 1fr;
    grid-template-rows: auto auto;

    > div {
      padding-right: 1rem;
      padding-bottom: 1rem;
    }
    > div:nth-child(1) {
      grid-row: span 2;

      img {
        max-width: 10rem;
        margin-right: 3rem;
      }
    }
    > div:nth-child(2) {
    }
    > div:nth-child(3) {
    }
    > div:nth-child(4) {
    }
    > div:nth-child(5) {
      grid-column: span 3;
      border-top: solid 1px #ffffff50;
      padding-top: 1rem;

      a img {
        margin-right: 1rem;
      }
    }

    .hey-sub-title {
      font-size: 1.5rem;
      line-height: 1.5rem;
      margin-bottom: 1rem;
    }
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      li {
        margin-bottom: 0.5rem;
      }
    }
  }

  @include small-size {
    .sitemap2 {
      > div {
        padding-right: 0.5rem;
      }
      > div:nth-child(1) {
        grid-row: span 5;

        img {
          max-width: 8rem;
        }
      }
      > div:nth-child(2) {
        grid-column: span 3;
      }
      > div:nth-child(3) {
        grid-column: span 3;
      }
      > div:nth-child(4) {
        grid-column: span 3;
      }
      > div:nth-child(5) {
        .flex-line {
          display: block;

          > div:first-child {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
}
</style>
