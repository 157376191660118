<script setup>
import { onMounted } from "vue";
import { Logger } from '@/plugins/loggerPlugin.js';

const logger = new Logger('HomeSection2');

onMounted(() => {
  logger.log("mounted");
});
</script>

<template>
  <div class="home-section-2 bg-orange">
    <!-- Background -->
    <div class="capsules-bg orange">
      <div class="orange-bg-gradient"></div>
    </div>
    <div style="height: 8rem"></div>

    <!-- Body -->
    <div class="container">
      <!-- Video Cards -->
      <div class="row-video-cards">
        <!-- Walk -->
        <div class="video-card">
          <video autoplay playsinline muted loop>
            <source src="videos/Comp 1.mp4" />
          </video>
          <div class="hey-pink-label">Walk</div>
          <div class="text-dark">
            Go outside and walk. Walk my Ron will get your steps.
          </div>
        </div>

        <!-- Collect -->
        <div class="video-card">
          <video autoplay playsinline muted loop>
            <source src="videos/Comp 2.mp4" />
          </video>
          <div class="hey-pink-label">Collect</div>
          <div class="text-dark">
            Every step you take gives you in game currency.
          </div>
        </div>

        <!-- Buy -->
        <div class="video-card">
          <video autoplay playsinline muted loop>
            <source src="videos/Comp 3.mp4" />
          </video>
          <div class="hey-pink-label">Buy</div>
          <div class="text-dark">
            Spend your coins in gacha costume machines.
          </div>
        </div>

        <!-- Dress Up -->
        <div class="video-card">
          <video autoplay playsinline muted loop>
            <source src="videos/Comp 4.mp4" />
          </video>
          <div class="hey-pink-label">Dress Up</div>
          <div class="text-dark">
            Show your swag to the Walk my Ron community.
          </div>
        </div>
      </div>

      <!-- Trailer -->
      <div class="row-trailer">
        <div class="hey-pink-label text-center mb-1">Launch Trailer</div>
        <div class="youtube-div">
          <div class="left-img-div">
            <img src="images/webp/hey_ron_video_left.webp" alt="" />
          </div>
          <iframe
            width="420"
            height="320"
            src="https://www.youtube.com/embed/mzLYg_WsXwc"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <div class="right-img-div">
            <img src="images/webp/hey_ron_video_right.webp" alt="" />
          </div>
        </div>
      </div>
    </div>

    <!-- Thunder! -->
    <div style="height: 3rem"></div>
    <div class="thunder-clip bg-orange"></div>
  </div>
</template>

<style lang="scss">
@import "@/assets/styles/hey-variables.scss";

.home-section-2 {
  .row-video-cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1rem;
    margin-bottom: 5rem;

    .video-card {
      text-align: center;

      video {
        width: 100%;
        margin-bottom: 1rem;
        border-radius: 1rem;
        box-shadow: 0 1rem 1rem #00000050;
      }
      div:nth-child(2) {
        margin-bottom: 0.5rem;
      }
      div:nth-child(3) {
      }
    }
  }

  .row-trailer {
    overflow: hidden;

    .youtube-div {
      display: flex;
      justify-content: center;

      iframe {
        box-shadow: 0 0.5rem 1rem #00000050;
        border-radius: 1rem;
      }
      .left-img-div {
        img {
          margin-right: -2px;
        }
      }
      .right-img-div {
        img {
          margin-left: -2px;
        }
      }
    }
  }

  @include small-size {
    .row-video-cards {
      grid-template-columns: 1fr 1fr;
    }
    .row-trailer {
      img {
        display: none;
      }
    }
  }
}
</style>
