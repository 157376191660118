<script setup>
import { onMounted } from "vue";
import { Logger } from "@/plugins/loggerPlugin.js";

const logger = new Logger("AboutSection1");

onMounted(() => {
  logger.log("mounted");
});
</script>

<template>
  <div class="about-section-1 bg-pink">
    <!-- Background -->
    <div class="capsules-bg pink" style="margin-top: -6rem">
      <div class="pink-bg-gradient"></div>
    </div>
    <div style="height: 3rem"></div>

    <!-- Our Story -->
    <div class="container">
      <div class="row-our-story">
        <div>
          <img
            src="/images/hey_ron_eggplant.png"
            alt=""
            style="margin: 0 1rem 0 -5rem"
          />
        </div>
        <div style="text-align: justify">
          <div class="hey-sub-title">Our Story</div>
          <p>
            Hey there! We are Triple Hey!, an innovative independent game studio
            situated in the vibrant heart of Montreal. We officially kicked
            things off in 2018 when three of us, driven by a mutual passion for
            creating immersive, addictive, and incredibly enjoyable mobile
            games, joined forces. Between us, we bring more than 15 years of
            experience in 3D art, UI design, programming, management, and art
            direction in the gaming industry.
          </p>
          <p>
            At Triple Hey!, our mission is simple: we want to bring joy and
            entertainment to gamers all around the world. Our games aren’t just
            fun; they’re an absolute blast!
          </p>
          <div class="logo-signature">
            <img src="/images/hey_logo_solid_white.webp" alt="" />
          </div>
        </div>
      </div>
    </div>

    <!-- Thunder! -->
    <div style="height: 3rem"></div>
    <div class="thunder-clip bg-pink"></div>
  </div>
</template>

<style lang="scss">
@import "@/assets/styles/hey-variables.scss";

.about-section-1 {
  .row-our-story {
    display: grid;
    grid-template-columns: auto 1fr;
  }
  .logo-signature {
    display: flex;
    justify-content: flex-end;
    img {
      width: 4rem;
      margin: 0.5rem;
    }
  }
}

@include small-size {
  .about-section-1 {
    img {
      display: none;
    }
    .row-our-story {
      grid-template-columns: 1fr;
    }
  }
}
</style>
