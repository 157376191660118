// Vue
import { createApp } from "vue";
import router from "@/router";
import App from "./App.vue";
import { loggerPlugin } from "@/plugins/loggerPlugin.js";
import firebasePlugin from "@/plugins/firebasePlugin";

// Styles
import "@/assets/styles/main.scss";

// Firebase
// https://console.firebase.google.com/project/tripleheywebsite
const firebaseConfig = {
  apiKey: "AIzaSyBWWSSvyoGzSJf7_FogQACQOayoZ5VEdRg",
  authDomain: "tripleheywebsite.firebaseapp.com",
  projectId: "tripleheywebsite",
  storageBucket: "tripleheywebsite.appspot.com",
  messagingSenderId: "25888447092",
  appId: "1:25888447092:web:7f2505ac33202e0d448f21",
  measurementId: "G-FR3JL0MHGL"
};

createApp(App)
  .use(loggerPlugin, { enable: true })
  .use(firebasePlugin, { config: firebaseConfig })
  .use(router)
  .mount("#app");
