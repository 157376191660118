<script setup>
import { onMounted } from "vue";
import { Logger } from '@/plugins/loggerPlugin.js';
import PrivacySection1 from "@/components/Privacy/PrivacySection1.vue";

const logger = new Logger('PrivacyPage');

onMounted(() => {
  logger.log("mounted");
});
</script>

<template>
  <PrivacySection1 />
</template>