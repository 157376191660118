<script setup>
import { ref, onMounted } from "vue";
import { Logger } from "@/plugins/loggerPlugin.js";

const logger = new Logger("Template");
const isFlipped = ref(false);

onMounted(() => {
  logger.log("mounted");
});

const flipCard = () => {
  isFlipped.value = !isFlipped.value;
};
</script>

<template>
  <div class="card" @click="flipCard">
    <div class="card-inner" :class="{ flipped: isFlipped }">
      <div class="card-front">
        <slot name="front"></slot>
      </div>
      <div class="card-back">
        <slot name="back"></slot>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/styles/hey-variables.scss";
.card {
  perspective: 1000px;
  display: inline-block;
  cursor: pointer;
  height: 32rem;
}
.card-inner {
  transform-style: preserve-3d;
  transition: transform 0.5s;
  height: 100%;
}
//.card:hover .card-inner,
.card-inner.flipped {
  transform: rotateY(180deg);
}
.card-front,
.card-back {
  position: absolute;
  backface-visibility: hidden;
  height: 100%;
}
.card-back {
  transform: rotateY(180deg);
}

</style>
