import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

export default {
  install: (app, { config }) => {
    const firebaseApp = initializeApp(config);
    getAnalytics(firebaseApp);
  }
};

// Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries