<script setup>
import { onMounted } from "vue";
import { Logger } from "@/plugins/loggerPlugin.js";
import ProfileCard from "@/components/ProfileCard.vue";

const logger = new Logger("AboutSection2");

onMounted(() => {
  logger.log("mounted");
});
</script>

<template>
  <div class="about-section-2 bg-orange">
    <!-- Background -->
    <div class="capsules-bg orange">
      <div class="orange-bg-gradient"></div>
    </div>
    <div style="height: 8rem"></div>

    <div class="container">
      <div class="hey-sub-title text-center mb-3">The Team</div>
      <div class="grid-profiles">
        <!-- Phil -->
        <ProfileCard>
          <template #front>
            <div class="profile-card-recto">
              <img src="images/webp/about_phil.webp" alt="" />
              <div class="profile-text">
                <div class="hey-white-tagline">Phillipe Côté</div>
                <div>Programmer</div>
              </div>
            </div>
          </template>
          <template #back>
            <div class="profile-card-verso">
              <img src="images/webp/hey_bracket_top.webp" alt="" />
              <div class="profile-quote hey-white-tagline">
                J’suis vraiment content d’avoir fait 8h d’avion pour voir ce
                spectacle!
              </div>
              <img src="images/webp/hey_bracket_bottom.webp" alt="" />
            </div>
          </template>
        </ProfileCard>

        <!-- Norm -->
        <ProfileCard>
          <template #front>
            <div class="profile-card-recto">
              <img src="images/webp/about_norm.webp" alt="" />
              <div class="profile-text">
                <div class="hey-white-tagline">Normand Gaudreau</div>
                <div>3D Artist</div>
              </div>
            </div>
          </template>
          <template #back>
            <div class="profile-card-verso">
              <img src="images/webp/hey_bracket_top.webp" alt="" />
              <div class="profile-quote hey-white-tagline">
                Can I get hm... two double vodka cranberry with just a little
                bit of 7up please?
              </div>
              <img src="images/webp/hey_bracket_bottom.webp" alt="" />
            </div>
          </template>
        </ProfileCard>

        <!-- Vince -->
        <ProfileCard>
          <template #front>
            <div class="profile-card-recto">
              <img src="images/webp/about_vince.webp" alt="" />
              <div class="profile-text">
                <div class="hey-white-tagline">Vincent Maltais</div>
                <div>2D/UI Artist</div>
              </div>
            </div>
          </template>
          <template #back>
            <div class="profile-card-verso">
              <img src="images/webp/hey_bracket_top.webp" alt="" />
              <div class="profile-quote hey-white-tagline">
                If you can give me any credit for recognizing what’s a hit more
                than a hit là!
              </div>
              <img src="images/webp/hey_bracket_bottom.webp" alt="" />
            </div>
          </template>
        </ProfileCard>

        <!-- Gui -->
        <ProfileCard>
          <template #front>
            <div class="profile-card-recto">
              <img src="images/webp/about_gui.webp" alt="" />
              <div class="profile-text">
                <div class="hey-white-tagline">Guillaume Riendeau</div>
                <div>Programmer</div>
              </div>
            </div>
          </template>
          <template #back>
            <div class="profile-card-verso">
              <img src="images/webp/hey_bracket_top.webp" alt="" />
              <div class="profile-quote hey-white-tagline">
                How un-Rock ’n Roll would it be, to not be Rock ’n Roll?
              </div>
              <img src="images/webp/hey_bracket_bottom.webp" alt="" />
            </div>
          </template>
        </ProfileCard>
      </div>
    </div>

    <!-- Thunder! -->
    <div style="height: 6rem"></div>
    <div class="thunder-clip bg-orange"></div>
  </div>
</template>

<style lang="scss">
@import "@/assets/styles/hey-variables.scss";

$card-border-radius: 1rem;

.about-section-2 {
  .grid-profiles {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
  }

  .profile-card-recto {
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100%;

    img {
      width: 100%;
      border-top-left-radius: $card-border-radius;
      border-top-right-radius: $card-border-radius;
    }
    .profile-text {
      display: grid;
      align-content: center;
      text-align: center;
      background-color: $hey-bg-pink;
      color: $hey-text-orange;
      padding: 0 1rem;
      border-bottom-left-radius: $card-border-radius;
      border-bottom-right-radius: $card-border-radius;
    }
  }

  .profile-card-verso {
    display: grid;
    align-content: center;
    justify-items: center;
    height: 100%;
    padding: 1rem 2rem;
    background-color: #9473f7;
    text-align: center;
    border-radius: $card-border-radius;

    img {
      margin: 2rem;
    }
  }
}

@include small-size {
  .about-section-2{
    .grid-profiles {
      max-width: 22rem;
      margin: 0 auto;
      grid-template-columns: 1fr;
    }
  }
}
</style>
