<script setup>
import { onMounted } from "vue";
import { Logger } from '@/plugins/loggerPlugin.js';

const logger = new Logger('SitePrefooter');

onMounted(() => {
  logger.log("mounted");
});
</script>

<template>
  <div class="site-prefooter bg-pink">
    <!-- Background -->
    <div class="capsules-bg pink">
      <div class="pink-bg-gradient"></div>
    </div>
    <div style="height: 5rem"></div>

    <!-- Body -->
    <div class="container">
      <div class="row-follow">
        <div class="get-walking">
          <div class="hey-title line1">
            Let's get
          </div>
          <div class="hey-title line2">
            Walking!
          </div>
          <div class="hey-orange-label line3">
            FOLLOW US
          </div>
          <div class="socials">
            <a href="https://www.facebook.com/studiotriplehey">
              <img src="images/webp/hey_social_large_fb.webp" alt="">
            </a>
            <a href="https://www.instagram.com/walkmyron/">
              <img src="images/webp/hey_social_large_ig.webp" alt="">
            </a>
          </div>
        </div>
        <div class="img-div">
          <img src="images/webp/hey_ron_trophy.webp" alt="" />
        </div>
      </div>
    </div>

    <!-- Thunder! -->
    <div style="height: 3rem"></div>
    <div class="thunder-clip bg-pink"></div>
  </div>
</template>

<style lang="scss">
@import "@/assets/styles/hey-variables.scss";

.site-prefooter {
  .row-follow {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;

    .get-walking{
      .line1{
        font-size: 3rem;
        line-height: 3rem
      }
      .line2{
        font-size: 4rem;
        line-height: 4rem
      }
      .socials {
        display: flex;

        img{
          width: 5.5rem;
          margin: 1rem 1rem 0 0;
        }
      }
    }
    .img-div img {
      max-width: 100%;
      margin-left: 1rem;
    }
  }

  @include small-size{
    .row-follow{
      display: block;
      .get-walking{
        margin-top: 2rem;
        margin-left: 2rem;
      }
      .img-div{
        margin-top: 2rem;
        display: flex;
        justify-content: center ;
      }
    }
  }
}
</style>
