<script setup>
import { onMounted } from "vue";
import { Logger } from "@/plugins/loggerPlugin.js";

const logger = new Logger("HomeSection1");

onMounted(() => {
  logger.log("mounted");
});
</script>

<template>
  <div class="home-section-1 bg-blue">
    <!-- Background -->
    <div class="capsules-bg blue">
      <div class="blue-bg-gradient"></div>
    </div>

    <!-- Body -->
    <div class="container">
      <!-- Title -->
      <div class="row-title">
        <div class="stacked-title hey-title text-center">
          <span>Up for a </span>
          <span>WALK?</span>
        </div>
        <div class="image-div">
          <img src="images/webp/hey_ron_lineup.webp" alt="" />
          <div class="blue-bg-gradient"></div>
        </div>
      </div>

      <!-- Introducing -->
      <div class="row-introducing">
        <div class="image-div">
          <img src="images/webp/hey_coins_girl.webp" alt="" />
        </div>
        <div class="text-center">
          <div class="hey-sub-title">
            Introducing <br />
            Walk My Ron!
          </div>
          <div class="hey-white-tagline">
            The pocket size <br />
            walking buddy!
          </div>
          <div>
            Walk my Ron is the most fun step tracker on the market. Make your
            every day steps count, get cool rewards and unlock over 700 cool
            outfits to showcase your sense of style to your walking buddies.
          </div>
        </div>
        <div class="image-div">
          <img src="images/webp/hey_mom_award.webp" alt="" />
        </div>
      </div>

      <!-- Store links -->
      <div class="row-store-links">
        <a
          href="https://apps.apple.com/us/app/walk-my-ron/id1524828314"
          target="_blank"
        >
          <img src="images/webp/appstore.webp" alt="" />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.triplehey.danceron"
          target="_blank"
        >
          <img src="images/webp/googleplay.webp" alt="" />
        </a>
      </div>
    </div>

    <!-- Thunder! -->
    <div style="height: 3rem"></div>
    <div class="thunder-clip bg-blue"></div>
  </div>
</template>

<style lang="scss">
@import "@/assets/styles/hey-variables.scss";

.home-section-1 {
  .capsules-bg {
    top: 0;
    height: 38rem;

    > div {
      height: 30rem;
    }
  }

  .row-title {
    margin-bottom: 3rem;

    .stacked-title {
      padding: 1rem 0;

      span:first-child {
        display: block;
        font-size: 3.5rem;
        line-height: 3.5rem;
      }
      span:nth-child(2) {
        display: block;
        font-size: 6rem;
        line-height: 6rem;
      }
    }
    .image-div {
      position: relative;

      img {
        width: 100%;
      }

      .blue-bg-gradient {
        position: absolute;
        bottom: -0.5rem;
        left: 0;
        right: 0;
        height: 6rem;
      }
    }
  }

  .row-introducing {
    display: grid;
    grid-template-columns: auto 1fr auto;
    margin-bottom: 2rem;

    .tag-line {
      font-size: 2rem;
      line-height: 2.2rem;
      margin: 0.5rem 0;
    }

    .image-div {
      padding: 2rem 0.5rem 0 0.5rem;
    }
  }

  .row-store-links {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    a {
      img {
        height: 3rem;
      }
    }
  }

  @include medium-size {
    .row-title {
      .image-div {
        .blue-bg-gradient {
          display: none;
        }
      }
    }
    .row-introducing {
      display: block;
      max-width: 25rem;
      margin-right: auto;
      margin-left: auto;

      .image-div {
        display: none;
      }
    }
  }

  @include small-size {
    .row-title {
      .stacked-title {
        span:first-child {
          font-size: 2.5rem;
          line-height: 2.5rem;
        }
        span:nth-child(2) {
          display: block;
          font-size: 4rem;
          line-height: 4rem;
        }
      }
    }
    .row-store-links {
      a {
        img {
          height: 2.5rem;
        }
      }
    }
  }
}
</style>
