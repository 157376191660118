<script setup>
import { onMounted } from "vue";
import { Logger } from "@/plugins/loggerPlugin.js";

const logger = new Logger("DataDeletionSection1");

onMounted(() => {
  logger.log("mounted");
});
</script>

<template>
  <div class="deletion-section-1 bg-pink">
    <!-- Background -->
    <div class="capsules-bg pink" style="margin-top: -6rem">
      <div class="pink-bg-gradient"></div>
    </div>
    <div style="height: 3rem"></div>

    <!-- Deletion -->
    <div class="container">
      <div class="row-deletion">
        <div>
          <img
            src="/images/hey_ron_eggplant.png"
            alt=""
            style="margin: 0 1rem 0 -5rem"
          />
        </div>
        <div style="text-align: justify">
          <div class="hey-sub-title">Data Deletion</div>
          <p>
            For data deletion requests, please reach out to <a href=mailto:support@triplehey.com>support@triplehey.com</a> with your specific query.
            If possible, include your in-game username and profile ID (which can be found in the profile screen in game)
          </p>
          <div class="logo-signature">
            <img src="/images/hey_logo_solid_white.webp" alt="" />
          </div>
        </div>
      </div>
    </div>

    <!-- Thunder! -->
    <div style="height: 3rem"></div>
    <div class="thunder-clip bg-pink"></div>
  </div>
</template>

<style lang="scss">
@import "@/assets/styles/hey-variables.scss";

.deletion-section-1 {
  .row-deletion {
    display: grid;
    grid-template-columns: auto 1fr;
  }
  .logo-signature {
    display: flex;
    justify-content: flex-end;
    img {
      width: 4rem;
      margin: 0.5rem;
    }
  }
}

@include small-size {
  .deletion-section-1 {
    img {
      display: none;
    }
    .row-deletion {
      grid-template-columns: 1fr;
    }
  }
}
</style>
