<script setup>
import { onMounted } from "vue";
import { Logger } from '@/plugins/loggerPlugin.js';
import HomeSection1 from "./HomeSection1.vue"
import HomeSection2 from "./HomeSection2.vue"
import SitePrefooter from "@/components/SitePrefooter.vue"

const logger = new Logger('HomePage');

onMounted(() => {
  logger.log("mounted");
});
</script>

<template>
  <HomeSection1 />
  <HomeSection2 />
  <SitePrefooter />
</template>