let isEnabled = false;

class Logger {
  constructor(componentName) {
    this.componentName = componentName || "undefined";
  }

  log(message) {
    if (isEnabled) {
      console.log(`${this.componentName} | ${message}`);
    }
  }
}

const loggerPlugin = {
  install: (app, { enable }) => {
    isEnabled = enable;
  }
};

export { Logger, loggerPlugin };