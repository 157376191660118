<script setup>
import { onMounted } from "vue";
import { Logger } from '@/plugins/loggerPlugin.js';
import DataDeletionSection1 from "@/components/DataDeletion/DataDeletionSection1.vue";

const logger = new Logger('DataDeletionPage');

onMounted(() => {
  logger.log("mounted");
});
</script>

<template>
  <DataDeletionSection1 />
</template>