<script setup>
import { onMounted } from "vue";
import { Logger } from "@/plugins/loggerPlugin.js";

const logger = new Logger("PrivacySection1");

onMounted(() => {
  logger.log("mounted");
});
</script>

<template>
  <div class="privacy-section-1 bg-pink">
    <!-- Background -->
    <div class="capsules-bg pink" style="margin-top: -6rem">
      <div class="pink-bg-gradient"></div>
    </div>
    <div style="height: 3rem"></div>

    <!-- Our Story -->
    <div class="container">
      <div style="text-align: justify">
        <div class="hey-sub-title">Privacy Policy</div>
        <p>Triple Hey! Studio Inc. (hereinafter «&nbsp;Triple Hey! ») acknowledges that the protection of your privacy and personal information is a legitimate concern and attaches great importance to it. The purpose of this privacy policy (hereinafter the "<strong>Policy</strong>") is to inform you about how we collect, use and disclose personal information that you may provide to us or that we may otherwise collect about you. This Policy is designed to comply with Canadian federal and provincial privacy laws.</p>

        <p><strong>1. Scope of Policy</strong></p>
        <p>This Policy applies to personal information that is in Triple Hey! possession or that is under its control, including information that Triple Hey! may exchange with a third party for storage or processing purposes.</p>

        <p><strong>2. Acceptance</strong></p>
        <p>Use of Triple Hey! game is subject to your compliance with its terms and conditions of use Dance Ron and this Policy. By accessing and using this game, you agree to be bound by the terms and conditions contained in these documents. If you do not agree to be bound by these terms and conditions, please do not access or use the features of our game.</p>
        <p>Please note that this Policy applies only to our game and does not apply to games of third-party companies or organizations to which our game may be linked. Triple Hey! does not endorse and is not responsible for these linked games.&nbsp;</p>
        <p>Your consent may be withdrawn at any time, subject to legal and contractual restrictions and provided that you give us reasonable notice. Please note that if you choose to withdraw your consent, Triple Hey! may not be able to provide you with certain products or services.</p>

        <p><strong>3. Amendments to the Policy</strong></p>
        <p>We reserve our right to amend this Policy at any time. Any amendments made will appear on our game. We, therefore, recommend that you review this Policy on a regular basis and keep a copy for your records.</p>

        <p><strong>4. Consent</strong></p>
        <p>We only collect personal information for which you have given clear, free and enlightened consent. Generally, we will seek your express consent at the time of the collection of personal information, unless we are otherwise required or permitted by the law. Your consent may also be implicit, for example, when you voluntarily provide us with personal information.</p>
        
        <p><strong>5. Collection of your personal information&nbsp;</strong></p>
        <p>Triple Hey! only collects the personal information that is necessary for the purposes of establishing, managing and maintaining our relationship with you. For example, personal information may be collected when you complete a transaction, or submit questions, comments or suggestions, and may include, without limitation, identification information such as your first and last name, gender, date of birth, home address, phone number, email address and payment information.&nbsp;</p>
        <p>Generally, we will obtain your personal information directly from you. However, we may also collect personal information about you from external sources with your consent, or without your consent if the law so authorizes.</p>

        <p><strong>6. Cookies and tracking information</strong></p>
        <p>Your use of this game allows Triple Hey! to automatically compile certain information regarding your user profile, which may include the date and time at which you accessed our game, the previous game you visited that provided you with a link to our game, and the content you viewed and downloaded from our game.&nbsp;</p>
        <p>We use this information to personalize our games based on your preferences, including advertisements displayed, and to compile statistics about the use and viewing of our game in order to improve it.</p>

        <p><strong>7. Use of your personal information</strong></p>
        <p>We may use your personal information, notably, for the following purposes:</p>
        <ul>
          <li>to provide you with a user account and to identify you as a user of our game, and as a customer, as applicable;&nbsp;</li>
          <li>to provide you with the product you purchased or the information you requested;</li>
          <li>to establish and maintain our business relationship with you;</li>
          <li>to improve our product and service offerings, including the use of this game;</li>
          <li>to communicate with you when you submit questions, comments or suggestions;</li>
          <li>to provide you with information about our business, products and services that may be of interest to you;</li>
          <li>to prevent errors and fraud; and</li>
          <li>to comply with our legal obligations.</li>
        </ul>
        <p>We may also use your information to compile statistics. However, these statistics will not allow you to be identified.</p>
        <p>Your personal information shall not be collected or used for other purposes than those described above, except with your consent or as required or authorized by law.</p>

        <p><strong>8. Communication of your personal information</strong></p>
        <p>We recognize that, except in the cases and under the conditions described hereinafter, the disclosure of your personal information to a third party requires your consent.</p>
        <p>Access to your personal information within Triple Hey! does not require your consent, but is strictly limited to the persons for whom the information is necessary for the performance of their functions and duties.</p>
        <p>We may also share your personal information, without your consent, with agents, representatives, mandataries, consultants, data processors, service providers and other parties for whom such information is necessary in the course of Triple Hey! activities or to assist Triple Hey! in the administration of our business relationship (assistance with promotions, provision of technical support, payment gateways, transaction processors, etc.). We will then take reasonable steps to ensure that your shared personal information is protected by them. Please note that in the event that the third party to whom Triple Hey! discloses your personal information is located in a foreign jurisdiction, local laws may otherwise allow access to your personal information by third parties without your consent.</p>
        <p>We may also disclose your personal information without your consent if required or authorized by law.&nbsp;</p>
        <p>Whenever Triple Hey! can or must communicate your personal information, Triple Hey! will endeavour not to communicate any more personal information than is necessary in the circumstances.</p>

        <p><strong>9. External links</strong></p>
        <p>Our game may contain links to other games that are not covered by this Policy. For this reason, we encourage you to review the privacy policy of such other games. We are not responsible for the practices of such other games relative to privacy.</p>

        <p><strong>10. Safeguards</strong></p>
        <p>Triple Hey! applies security measures that are reasonable and appropriate in regard to the sensitivity of the personal information that we collect and use. Such measures are aimed at protecting your personal information against loss or theft, and the unauthorized consultation, disclosure, reproduction, use or modification thereof. These security measures are reviewed periodically when need be.</p>
        <p>These security measures include physical, organizational and technological measures that are reasonable given the sensitivity of the information, the purposes for which it is to be used, the quantity and distribution of the information and the medium on which it is stored.</p>

        <p><strong>11. Accuracy</strong></p>
        <p>We take reasonable steps to ensure that the personal information we have collected is accurate and complete. However, it is your responsibility to inform us of any changes to your personal information which may occur during the course of our business relationship. We will, upon request, inform you of the existence, of the use and of the disclosure to third parties of your personal information, and give you access to said information.</p>

        <p><strong>12. Questions and access or rectification requests</strong></p>
        <p>Should you have any questions or concerns about this Policy or about the collection, use and disclosure of your personal information, including requests for access or rectification, please contact :</p>
        <p style="line-height:0;">Triple Hey! Studio</p>
        <p style="line-height:0;">Email: <a href="mailto:support@triplehey.com">support@triplehey.freshdesk.com</a></p>
        <p>You have the right to consult and obtain communication of your personal information contained in a file held by Triple Hey!. You can also request that inaccurate, incomplete or equivocal information be rectified and that information held for reasons not justified by the purpose of the file be deleted.</p>
        <p>Triple Hey! will respond to every request for access or rectification within 30 days of receiving a written request to this effect. In the event that Triple Hey! cannot provide access or rectify your personal information, it will provide you with the reasons for this impediment, subject to any restrictions provided by the law, and will inform you of any recourses available.<br></p>
        <p>This Policy was last updated on January 1<sup>st</sup>, 2021.</p>
        <div class="logo-signature">
          <img src="/images/hey_logo_solid_white.webp" alt="" />
        </div>
      </div>
    </div>

    <!-- Thunder! -->
    <div style="height: 3rem"></div>
    <div class="thunder-clip bg-pink"></div>
  </div>
</template>

<style lang="scss">
@import "@/assets/styles/hey-variables.scss";

.privacy-section-1 {
  .logo-signature {
    display: flex;
    justify-content: flex-end;
    img {
      width: 4rem;
      margin: 0.5rem;
    }
  }
}

@include small-size {
  .privacy-section-1 {
    img {
      display: none;
    }
  }
}
</style>
