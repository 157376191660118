<script setup>
import { onMounted } from "vue";
import { Logger } from '@/plugins/loggerPlugin.js';
import AboutSection1 from "@/components/About/AboutSection1.vue";
import AboutSection2 from "@/components/About/AboutSection2.vue";
import SitePrefooter from "@/components/SitePrefooter.vue";

const logger = new Logger('AboutPage');

onMounted(() => {
  logger.log("mounted");
});
</script>

<template>
  <AboutSection1 />
  <AboutSection2 />
  <SitePrefooter />
</template>