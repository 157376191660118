import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../components/Home/HomePage.vue'
import About from '../components/About/AboutPage.vue'
import DataDeletion from '../components/DataDeletion/DataDeletionPage.vue'
import Privacy from '../components/Privacy/PrivacyPage.vue'

const routes = [
  {
    path: '/',
    name: 'Triple Hey!',
    component: HomePage
  },
  {
    path: '/about',
    name: 'About - Triple Hey!',
    component: About
  },
  {
    path: '/datadeletion',
    name: 'Data Deltion - Triple Hey!',
    component: DataDeletion
  },
  {
    path: '/privacy',
    name: 'Privacy - Triple Hey!',
    component: Privacy
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(){
    return { top: 0 }
  }
})

// Set document name (beforeEach to work with analytics)
router.beforeEach((to) => {
  const pageTitle = to.meta.title || to.name;
  document.title = pageTitle;
});

export default router
